<template>
  <!-- 我的资料 -->
  <div class="content">
    <div class="content_message">
      <div v-for="item in datalist" :key="item.text" class="dataList" :class="item.class">
        <div class="listslide">
          <p>{{ item.text }}</p>
          <div @click="uploadAvatar(item.query)">
            <!-- 学历选择 -->
            <el-select size="small" v-show="item.type === 'select'" v-model="item.val" placeholder="请选择" style="width: 300px">
              <el-option v-for="itemx in item.education" :key="itemx.value" :label="itemx.value" :value="itemx.value"> </el-option>
            </el-select>
            <!-- 性别单选 -->
            <el-radio-group v-show="item.type === 'radio'" v-model="item.val">
              <el-radio v-for="(items, indexs) in item.gender" :key="indexs" :label="items.value">{{ items.label }}</el-radio>
            </el-radio-group>
            <!-- 生日 -->
            <el-date-picker size="small" v-if="item.type === 'dateIshow'" style="width: 300px" v-model="item.val" value-format="yyyy-MM-dd" type="date"> </el-date-picker>
            <!-- 学校、籍贯、邮箱 -->
            <el-input size="small" v-filteremoji v-show="item.type === 'input'" style="width: 300px" v-model="item.val" placeholder="请输入"></el-input>
            <!-- 头像 -->
            <img v-show="item.type === 'img' && item.val" class="avatarImg" :src="item.val" alt="" />
            <div v-show="item.type === 'img' && !item.val" class="avatarImg avatardefault">{{ avatarName }}</div>
            <!-- 带锁的文字 -->
            <span class="lockfont" v-show="item.type === 'textlock'">{{ item.val }}</span>
            <!-- 图片右箭头 -->
            <span v-show="item.type === 'img'" class="iconfont iconjiantou"></span>
            <!-- 图片锁 -->
            <i v-show="item.type === 'textlock'" class="iconfont iconxingzhuang2"></i>
            <!-- 勋章 -->
            <div v-show="item.type === 'iconfin'" class="medalGrade">
              <div v-for="(item, index) in item.list" :key="index" class="icon">
                <img :src="item.icon" />
                <span :style="{ color: item.color }">{{ item.medalNum | filterMedalNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footBtn">
      <el-button @click="userdata">重置</el-button>
      <el-button type="primary" @click="saveinfodata">保存</el-button>
    </div>
    <cropPer ref="dialog" @avatarUrl="avatarUrl" @centerDialogVisible="centerDialogVisible" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { userdata, editUser } from '@/api/employeedata.js'
import cropPer from '@/components/common/cropper/cropper.vue'
import { medal } from '@/config/config.js'
export default {
  name: 'personalCenter-material',
  data() {
    return {
      centerDialogVisible: false,
      datalist: [
        {
          text: '头像',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'avatar',
          type: 'img',
          class: 'borderRadius'
        },
        {
          text: '姓名',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'userName',
          type: 'textlock'
        },
        {
          text: '勋章',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'medalGrade',
          type: 'iconfin',
          list: []
        },
        {
          text: '部门',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'jobList',
          type: 'textlock'
        },
        {
          text: '员工编号',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'workNo',
          type: 'textlock',
          class: 'borderRadiusBottom'
        },
        {
          text: '毕业院校',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          class: 'interval borderRadius',
          query: 'graduationSchool',
          type: 'input'
        },
        {
          text: '学历',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'highestEducation',
          type: 'select',
          class: 'borderRadiusBottom',
          education: [
            {
              value: '博士',
              label: '博士'
            },
            {
              value: 'MBA/EMBA',
              label: 'MBA/EMBA'
            },
            {
              value: '硕士',
              label: '硕士'
            },
            {
              value: '本科',
              label: '本科'
            },
            {
              value: '大专',
              label: '大专'
            },
            {
              value: '中专/中技',
              label: '中专/中技'
            },
            {
              value: '高中',
              label: '高中'
            },
            {
              value: '初中及以下',
              label: '初中及以下'
            }
          ]
        },
        {
          text: '性别',
          imgUrl: '',
          val: 5,
          leftbar: require('../../assets/images/rightoption.svg'),
          class: 'interval borderRadius',
          query: 'sex',
          type: 'radio',
          gender: [
            {
              label: '男',
              value: 1
            },
            {
              label: '女',
              value: 2
            }
          ]
        },
        {
          text: '生日',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'birthDate',
          type: 'dateIshow'
        },
        {
          text: '籍贯',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'birthPlace',
          type: 'input'
        },
        {
          text: '邮箱',
          imgUrl: '',
          val: '',
          leftbar: require('../../assets/images/rightoption.svg'),
          query: 'email',
          type: 'input',
          class: 'borderRadiusBottom'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      avatarName: state => (state.userInfo.userName ? state.userInfo.userName.slice(-2) : '')
    })
  },
  created() {
    this.userdata()
  },
  methods: {
    // 获取个人资料的方法
    userdata() {
      userdata().then(result => {
        if (result && result.code === '000000') {
          let data = result.data
          for (let i = 0; i < this.datalist.length; i++) {
            for (let val in data) {
              if (this.datalist[i].query === val) {
                // 处理头像
                if (this.datalist[i].query === 'avatar' && data[val]) {
                  this.datalist[i].val = data[val]
                }
                // 处理员工所属部门
                if (this.datalist[i].query == 'jobList') {
                  if (data.jobList.length > 0) {
                    this.datalist[i].val = data.jobList[0].orgName
                    continue
                  } else {
                    this.datalist[i].val = ''
                    continue
                  }
                }
                // 处理生日
                if (this.datalist[i].query == 'birthDate') {
                  this.datalist[i].val = data.birthDate ? data.birthDate.replace(/\./g, '-') : ''
                  continue
                }
                // 处理性别
                if (this.datalist[i].query === 'sex') {
                  this.datalist[i].val = Number(data.sex)
                  continue
                }
                // 处理勋章
                if (this.datalist[i].query === 'medalGrade') {
                  let medalGrade = data.medalGrade
                  if (medalGrade && medalGrade.length > 0) {
                    medal.forEach(element => {
                      medalGrade.forEach(e => {
                        if (element.medalType === e.medalType) {
                          e.icon = element.icon
                          e.color = element.color
                        }
                      })
                    })
                  }
                  this.datalist[i].list = medalGrade
                }
                this.datalist[i].val = data[val]
              }
            }
          }
        } else {
          Message({
            message: '获取个人资料失败！',
            type: 'error'
          })
        }
      })
    },
    avatarUrl(data) {
      for (let i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].query == 'avatar') {
          this.datalist[i].val = data
          return false
        }
      }
    },
    // 点击保存按钮
    saveinfodata() {
      this.editUser()
    },
    // 修改个人资料的方法
    editUser() {
      let data = {}
      for (let i = 0; i < this.datalist.length; i++) {
        // 获取头像
        if (this.datalist[i].query === 'avatar') {
          data.avatar = this.datalist[i].val
          continue
        }
        // 获取毕业院校
        if (this.datalist[i].query === 'graduationSchool') {
          data.graduationSchool = this.datalist[i].val
          continue
        }
        // 获取学历
        if (this.datalist[i].query === 'highestEducation') {
          data.highestEducation = this.datalist[i].val
          continue
        }
        // 获取性别
        if (this.datalist[i].query === 'sex') {
          data.sex = this.datalist[i].val
          continue
        }
        // 获取生日
        if (this.datalist[i].query === 'birthDate') {
          data.birthDate = this.datalist[i].val.replace(/-/g, '-')
          continue
        }
        // 获取籍贯
        if (this.datalist[i].query === 'birthPlace') {
          data.birthPlace = this.datalist[i].val
          continue
        }
        // 获取邮箱
        if (this.datalist[i].query === 'email') {
          data.email = this.datalist[i].val
          continue
        }
      }
      editUser(data).then(res => {
        if (res && res.code === '000000') {
          Message({
            message: '修改我的信息成功！！',
            type: 'success'
          })
          this.$store.dispatch('getUserInfo')
          this.userdata()
        } else {
          Message({
            message: '修改我的信息失败！！',
            type: 'error'
          })
        }
      })
    },
    uploadAvatar(val) {
      if (val !== 'avatar') {
        return false
      }
      this.$refs.dialog.changeStatus()
    }
  },
  components: {
    cropPer
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: calc(100% - 60px);
  margin: 20px 30px 0 30px;
  .content_message {
    margin-bottom: 84px;
    .interval {
      margin-top: 20px;
    }
    .borderRadius {
      border-radius: 5px 5px 0 0;
    }
    .borderRadiusBottom {
      border-radius: 0 0 5px 5px;
      .listslide {
        border-bottom: none !important;
      }
    }
    .lockfont {
      font-size: 15px;
      color: #999999;
    }
    .dataList {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      .listslide {
        width: calc(100% - 15px);
        height: 100%;
        margin-left: 15px;
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
      }
      .iconxingzhuang2 {
        font-size: 16px;
        color: #ccc;
        margin-left: 10px;
      }
      p {
        width: 100px;
        font-size: 14px;
        color: #333333;
      }
      .iconjiantou {
        font-size: 12px;
        color: #ccc;
        margin-left: 10px;
      }
      .avatarImg {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        cursor: pointer;
      }
      .avatardefault {
        background: $blue;
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        display: inline-block;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
  }
  .footBtn {
    width: calc(80% - 50px);
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    .el-button {
      width: 167px;
      height: 44px;
    }
  }
}
</style>
