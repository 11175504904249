<template>
  <div class="dialog">
    <el-dialog title="上传头像" :visible.sync="centerDialogVisible" center width="1000px">
      <div class="header">
        <div class="test-button">
          <label class="btn" for="uploads">上传照片</label>
          <input type="file" ref="uploads" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg" />
        </div>
        <span class="description">大小不超过2M,支持JPG、GIF、PNG格式</span>
      </div>
      <div class="content">
        <div class="content_left">
          <vueCropper
            class="vueCropper"
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :autoCrop="option.autoCrop"
            :canMoveBox="option.canMoveBox"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            @realTime="realTime"
          ></vueCropper>
          <div class="features">
            <i @click="rotateLeft" class="el-icon-refresh-left"></i>
            <i @click="rotateRight" class="el-icon-refresh-right"></i>
            <el-button @click="rotateClear" size="small">原图</el-button>
          </div>
        </div>
        <div class="lookright">
          <div :style="previewStyle1">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
          <div :style="previewStyle2">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
          <div :style="previewStyle3">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
          <div :style="previewStyle4">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadQuery">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { VueCropper } from 'vue-cropper'
import { upload } from '@/api/employeedata.js'
export default {
  props: {
    // centerDialogVisible: {
    //     type: Boolean,
    //     default: false
    // }
  },
  data() {
    return {
      fileName: '',
      imageUrl: '',
      previews: {},
      previewStyle1: {},
      previewStyle2: {},
      previewStyle3: {},
      previewStyle4: {},
      centerDialogVisible: false,
      file: {},
      option: {
        fixedNumber: [1, 1],
        img: '',
        size: 1,
        outputType: 'png',
        autoCrop: true,
        original: true,
        canScale: true,
        fixedBox: true,
        canMove: true,
        canMoveBox: false,
        centerBox: true,
        info: true,
        fixed: true,
        autoCropWidth: 1,
        autoCropHeight: 1
      }
    }
  },
  methods: {
    uploadImg(e) {
      var file = e.target.files[0]
      this.file = file
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        this.$msgError('图片类型必须是.gif,jpeg,jpg,png中的一种')
        return false
      }
      if (file.size / 1024 / 1024 > 2) {
        this.$msgError('图片大小不超过2M')
        return false
      }
      var reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file)
      this.fileName = file.name
    },
    realTime(data) {
      this.previews = data
      var previews = data
      this.previewStyle1 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        marginRight: '15px',
        zoom: 0.8
      }
      this.previewStyle2 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        marginRight: '15px',
        zoom: 0.6
      }
      this.previewStyle3 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        marginRight: '15px',
        zoom: 0.4
      }
      this.previewStyle4 = {
        width: previews.w + 'px',
        height: previews.h + 'px',
        overflow: 'hidden',
        marginRight: '15px',
        zoom: 0.2
      }
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    rotateClear() {
      this.$refs.cropper.rotateClear()
    },
    changeStatus() {
      this.centerDialogVisible = !this.centerDialogVisible
    },
    toBlob(ndata) {
      //ndata为base64格式地址
      let arr = ndata.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {
        type: mime
      })
    },
    uploadQuery() {
      this.$refs.cropper.getCropData(data => {
        let img = this.toBlob(data)
        let files = new File([img], this.fileName, { type: img.type })
        const formdata = new FormData()
        formdata.append('files', files)
        formdata.append('business', 'user-avatar')
        upload(formdata).then(res => {
          if (res && res.code === '000000') {
            Message({
              message: '头像上传成功！',
              type: 'success'
            })
            this.$emit('avatarUrl', res.data.list[0])
            this.centerDialogVisible = false
          } else {
            Message({
              message: '头像上传失败！',
              type: 'error'
            })
          }
        })
      })
    }
  },
  components: {
    VueCropper
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  .test-button {
    width: 90px;
    height: 32px;
    line-height: 32px;
    background: $blue;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    label {
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;
    }
  }
  .test-button:hover {
    opacity: 0.8;
  }
  .description {
    margin-left: 20px;
    font-size: 12px;
    color: #666666;
  }
}

.content {
  display: flex;
  margin-top: 30px;
  .content_left {
    .vueCropper {
      width: 300px;
      height: 360px;
    }
    .features {
      margin: 0 auto;
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      i {
        font-size: 30px;
        cursor: pointer;
      }
    }
  }
  .lookright {
    width: 600px;
    height: 360px;
    padding-bottom: 60px;
    margin-left: 30px;
    border-left: 1px solid #ccc;
    display: flex;
    align-items: flex-end;
    padding-left: 30px;
  }
}
</style>
